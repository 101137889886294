<template>
  <v-app>
    <v-app-bar color="primary" dark app>
      <v-app-bar-title class="hidden-sm-and-down me-4">{{ $store.state.system.title }}</v-app-bar-title>
      <v-tabs>
        <v-tab to="/">首页</v-tab>
        <v-tab to="/account">账号管理</v-tab>
        <v-tab to="/platform">平台管理</v-tab>
        <v-tab to="/phone">手机号</v-tab>
      </v-tabs>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer color="primary" dark>
      <v-row justify="center" no-gutters>
        <!--        <v-btn text rounded class="my-2">关于我们</v-btn>-->
        <!--        <v-btn text rounded class="my-2">常见问题</v-btn>-->
        <!--        <v-btn text rounded class="my-2">联系我们</v-btn>-->
        <v-col class="text-center white--text my-2" cols="12">
          Copyright &copy; {{ new Date().getFullYear() }} {{ $store.state.system.title }}. All rights reserved.<br>
          Developed by King杨CN, Powered by love❤.
        </v-col>
      </v-row>

    </v-footer>
  </v-app>
</template>

<script>
export default {
  data() {
    return {}
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.axios.get(`basic/system`)
          .then(response => {
            this.$store.commit('init', response.data)
          })
    }
  }
}
</script>

